<template>
  <power-bi code="eyJrIjoiYzNmZTllMDktMDMxZC00OTQ1LWE4NWQtNDJjOGY5ODM3NTg1IiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9" />
</template>

<script>
import PowerBi from '../components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
